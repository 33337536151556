import { type ContentTemplate, type RawContentTemplate } from '@vakantiesnl/types/src';

import { mapContentTemplate } from '../contentful/mapper';
import { resolveBySlug } from '../contentful/slug-resolver';
import { filterContentfulSlugFromPath } from '../util/urlUtils';

export const getCachedContentTemplateFromContentful = async (
	asPath: string,
	dynamicContent?: string,
): Promise<ContentTemplate> => {
	const contentfulSlug = filterContentfulSlugFromPath(asPath);
	const rawData = await resolveBySlug<RawContentTemplate>(contentfulSlug, undefined);
	return mapContentTemplate(rawData, dynamicContent);
};
