import { type AdlinksParams, type ClickTrackingBody } from '@vakantiesnl/types/src/adlinks';

export const createAdlinkRequest = ({ id }: AdlinksParams): RequestInfo => {
	return new Request(`${process.env.NEXT_PUBLIC_ZVR_ADLINKS_ENDPOINT_URL}/api/adlinks/accommodations/${id}`);
};

export const createTrackAdlinkClickRequest = (body: ClickTrackingBody): RequestInfo => {
	return new Request(`${process.env.NEXT_PUBLIC_ZVR_TRACKING_ENDPOINT_URL}/api/tracking/click`, {
		method: 'POST',
		body: JSON.stringify(body),
	});
};
