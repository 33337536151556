import { checkCache, isZoover, storeInCache } from '@vakantiesnl/components/src/utils';
import request from 'graphql-request';

import { CONTENTFUL_GQL_URL } from '../ContentfulClient';
import { type BrandedSearchPageQueryResponse } from './types';
import { OWN_TRANSPORT_SLUG } from '../../constants/setOwnTransport';
import { getDaysInMs, getMinutesInMs } from '../../util';
import { searchTemplateQuery } from '../queries/brandedSearchTemplate';

export const BRANDED_SEARCH_TEMPLATE_CACHE_KEY = `${isZoover ? 'zvr' : 'vaknl'}-contentful-branded-search-template`;

export const DEFAULT_FALLBACK_TEMPLATE = '/zoeken';

export type BrandedSearchTemplateResponse = { slug: string; result: BrandedSearchPageQueryResponse | null };

export async function getBrandedSearchTemplateResponse(
	contentfulSlug: string | undefined,
	fallbackSlug: string | undefined,
	locale?: string,
	hasLogsEnabled = false,
): Promise<BrandedSearchTemplateResponse> {
	if (contentfulSlug) {
		const template = await getCachedOrFetchTemplate(contentfulSlug, locale, getMinutesInMs(30), hasLogsEnabled);
		if (template) {
			return {
				slug: contentfulSlug,
				result: template,
			};
		}

		const contentfulSlugWithOtherTransport = contentfulSlug.includes(OWN_TRANSPORT_SLUG)
			? contentfulSlug.replace(`/${OWN_TRANSPORT_SLUG}`, '')
			: `${contentfulSlug}/${OWN_TRANSPORT_SLUG}`;

		// For now just fallback to the template with our without own transport
		if (contentfulSlugWithOtherTransport) {
			const template = await getCachedOrFetchTemplate(
				contentfulSlugWithOtherTransport,
				locale,
				getMinutesInMs(30),
			);
			if (template) {
				return {
					slug: contentfulSlugWithOtherTransport,
					result: template,
				};
			}
		}
	}

	if (fallbackSlug) {
		const fallbackTemplate = await getCachedOrFetchTemplate(fallbackSlug, locale, getDaysInMs(1), hasLogsEnabled);
		if (fallbackTemplate) {
			return {
				slug: fallbackSlug,
				result: fallbackTemplate,
			};
		}
	}

	const defaultTemplate = await getCachedOrFetchTemplate(
		DEFAULT_FALLBACK_TEMPLATE,
		locale,
		getDaysInMs(1),
		hasLogsEnabled,
	);

	return {
		slug: DEFAULT_FALLBACK_TEMPLATE,
		result: defaultTemplate || null,
	};
}

async function getCachedOrFetchTemplate(
	slug: string,
	locale = 'nl-NL',
	cacheTimeInMs?: number,
	hasLogsEnabled = false,
): Promise<BrandedSearchPageQueryResponse | null> {
	const cachedTemplate = await checkCache<BrandedSearchPageQueryResponse>(
		`${BRANDED_SEARCH_TEMPLATE_CACHE_KEY}:${slug}:${locale}`,
		hasLogsEnabled,
	);

	// If there is cache, then return the template if its valid, otherwise return null,
	// so possible fallback templates can be requested instead
	if (cachedTemplate) {
		if (hasLogsEnabled) {
			console.log(
				`Found template in Redis for slug ${slug}, has items: ${!!cachedTemplate.templateBrandedSearchPageCollection?.items.length}`,
			);
		}

		return !!cachedTemplate.templateBrandedSearchPageCollection?.items.length ? cachedTemplate : null;
	}

	// If there is no cache, we fetch the template, store it in the cache and return it
	const template = await request<BrandedSearchPageQueryResponse>({
		url: CONTENTFUL_GQL_URL,
		document: searchTemplateQuery,
		variables: { slug: slug, contentItemsLimit: 10, contentItemsSkip: 10 },
		requestHeaders: { Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_API_KEY}` },
	});

	const hasTemplate = !!template?.templateBrandedSearchPageCollection?.items.length;

	if (hasTemplate) {
		await storeInCache(
			`${BRANDED_SEARCH_TEMPLATE_CACHE_KEY}:${slug}:${locale}`,
			template,
			cacheTimeInMs,
			hasLogsEnabled,
		);
		return template;
	} else {
		await storeInCache(`${BRANDED_SEARCH_TEMPLATE_CACHE_KEY}:${slug}:${locale}`, {}, cacheTimeInMs, hasLogsEnabled);
		return null;
	}
}
