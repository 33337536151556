import { gql } from 'graphql-request';

export const FRAGMENT_IMAGE_FIELDS = gql`
	fragment imageAsset on Asset {
		url
		title
		width
		height
		description
	}
`;

export const FRAGMENT_SEO_FIELDS = gql`
	fragment seoFields on Seo {
		slug
		title
		description
		robots
		canonical
		pageType
		gtmPageType
		queryParams
	}
`;
