import { isZoover } from '@vakantiesnl/components/src/utils';
import { isElementNavItem } from '@vakantiesnl/components/src/utils/contentfulMenuHelpers';
import { message } from '@vakantiesnl/components/src/utils/message';
import { errorHandler } from '@vakantiesnl/services/src/util/errorHandling';
import {
	type VaknlHeaderBlock,
	type Partner,
	type VaknlRawHeaderBlock,
	type RawPartner,
	type RawUsp,
	type Usp,
	type RawContentfulLink,
	type ContentfulLink,
	type MicroCopy,
	type FooterBlock,
	type RawFooterBlock,
	type RawSeo,
	type Seo,
	type RawTemplateHomepage,
	type TemplateHomepage,
	type RichTextElement,
	type RichTextElementMap,
	type BrandedSearchPageCollection,
	type RawBrandedSearchPageCollection,
	type RawBrandedSearchPageTemplate,
	type RawAlertElement,
	type AlertElement,
	type RawContentTemplate,
	type ContentTemplate,
	type RawUnorderedList,
	type UnorderedListType,
	type HeaderWithTick,
	type RawHeaderWithTick,
	type RawButtonElement,
	type ButtonElement,
	type RawButtonRow,
	type ButtonRow,
	type RawImageGrid,
	type ImageGrid,
	type RawImageCard,
	type ImageCard,
	type RawSearchBlock,
	type SearchBlock,
	type LinkCard,
	type RawImageLink,
	type ImageLink,
	type MappedImageAsset,
	type MenuLink,
	type ElementNavItem,
	type RawLinkList,
	type RawElementNavItem,
	type LinkList,
	type RawNavigationItem,
} from '@vakantiesnl/types';
import {
	type CollectionItem,
	type DynamicPageTemplateContent,
	type RawCollectionItem,
	type RawDynamicPageTemplateContent,
} from '@vakantiesnl/types/src/contentful';
import { type Asset, type Entry, type EntryCollection } from 'contentful';

import { isNotEmpty } from '../util/arrayUtils';
import { asValueOrNull } from '../util/asValueOrNull';

type UnknownEntry = Entry<unknown>;

// Filters the include entries from the entry collection response and sorts it based on the matchingIds => sys.id
export const filterIncludes = <T>(data: EntryCollection<unknown>, matchingIds: string[]): T[] =>
	data.includes.Entry.filter((item: UnknownEntry) => matchingIds.includes(item.sys.id)).sort(
		(a: UnknownEntry, b: UnknownEntry) => matchingIds.indexOf(a.sys.id) - matchingIds.indexOf(b.sys.id),
	);

export const mapMedia = (media: Asset): Asset => ({
	...media,
	fields: {
		...media.fields,
		file: {
			...media.fields.file,
			fileName: media.fields.file.fileName,
			url: media.fields.file.url,
		},
	},
});

export const mapPartner = ({ fields }: RawPartner): Partner => ({
	title: fields.title,
	href: fields.link,
	imgSrc: fields.image.fields.file.url,
	imgHoverSrc: fields.hoverImage.fields.file.url,
});

export const getTarget = (url?: string): '_self' | '_blank' => (url && url.startsWith('/') ? '_self' : '_blank');

export const mapLink = (fields: RawContentfulLink['fields']): ContentfulLink | null => {
	let link = fields.link ? fields.link : fields.page?.fields?.slug;
	let cardImage = null;

	if (!link) {
		return null;
	}
	if (fields.page?.fields?.queryParams) {
		link += fields.page?.fields?.queryParams;
	}

	/** Maps contentful and rest */
	if (fields.cardImage) {
		cardImage = 'url' in fields.cardImage ? asValueOrNull(fields.cardImage.url) : asValueOrNull(fields.cardImage);
	}

	return {
		title: fields.title,
		link,
		target: fields.isExternal ? '_blank' : '_self',
		isExternal: fields.isExternal,
		iconType: fields.iconType,
		cardImage,
	};
};

export const mapUsp = ({ fields }: RawUsp): Usp => ({
	title: fields.title,
	subtitle: fields.subtitle ? fields.subtitle : undefined,
	image: fields.image ? mapMedia(fields.image) : undefined,
	type: fields.type,
});

export const mapHeaderBlock = (data: EntryCollection<VaknlRawHeaderBlock>): VaknlHeaderBlock => {
	const uspsIds = data.items[0].fields.usps.map((item) => item.sys.id);
	const partnerIds = data.items[0].fields.partners.map((item) => item.sys.id);
	const paymentPartnerIds = data.items[0].fields.paymentPartners.map((item) => item.sys.id);
	const menuItemsIds = data.items[0].fields.menuItems.map((item) => item.sys.id);
	const subMenuItemsIds = data.items[0].fields.subMenuItems.map((item) => item.sys.id);

	return {
		title: data.items[0].fields.title,
		paymentPartners: filterIncludes<RawPartner>(data, paymentPartnerIds).map(mapPartner),
		partners: filterIncludes<RawPartner>(data, partnerIds).map(mapPartner),
		usps: filterIncludes<RawUsp>(data, uspsIds).map(mapUsp),
		menuItems: filterIncludes<RawContentfulLink>(data, menuItemsIds)
			.map((item) => mapLink(item.fields))
			.filter(isNotEmpty),
		subMenuItems: filterIncludes<RawContentfulLink>(data, subMenuItemsIds)
			.map((item) => mapLink(item.fields))
			.filter(isNotEmpty),
	};
};

/** Temporary editing of the metadata description about booking costs.
 * To be solved on the long term in https://vakanties.atlassian.net/browse/WEB-4935
 */
export const mapSeoDescription = (description: string): string => {
	const newText = isZoover ? 'Laagste prijsgarantie' : 'ANVR en SGR zekerheid';
	return description.replace(/geen boekingskosten|géén boekingskosten/gi, newText);
};

export const mapSeo = (data: RawSeo): Seo => ({
	title: data.title,
	description: mapSeoDescription(data.description),
	image: data.image ? data.image.fields.file.url : null,
	robots: data.robots,
	canonical: data.canonical,
	pageType: data.pageType,
	slug: data.slug,
	queryParams: asValueOrNull(data.queryParams),
	gtmPageType: data.gtmPageType,
	preload: asValueOrNull(data.preload),
});

export const mapSearchBlock = (data: RawSearchBlock): SearchBlock => ({
	title: data.title,
	suggestion: data.suggestion,
	cta: mapButtonElement(data.cta.fields),
});

export const mapLinkCard = (data: RawBrandedSearchPageTemplate): LinkCard => {
	return {
		title: data.fields.title,
		imgSrc: data.fields.headerImage?.fields && data.fields.headerImage.fields.file.url,
		cardImage: data.fields.cardImage?.fields && data.fields.cardImage.fields.file.url,
		verticalImgSrc: data.fields.verticalImage?.fields && data.fields.verticalImage.fields.file.url,
		target: '_self',
		iconType: data.fields.iconType,
		queryParams: data.fields.seo.fields.queryParams,
		slug: data.fields.seo.fields.slug,
		cheapestPrice: data.fields.cheapestPrice ? data.fields.cheapestPrice * 100 : undefined,
	};
};

/** Only map cards when they have an image */
export const mapBrandedSearchCards = (data: RawBrandedSearchPageTemplate[]): LinkCard[] =>
	data
		.filter(
			(data: RawBrandedSearchPageTemplate) =>
				data.fields?.seo?.fields?.slug &&
				(data.fields?.headerImage?.fields || data.fields?.cardImage?.fields || data.fields?.iconType),
		)
		.map((data: RawBrandedSearchPageTemplate) => mapLinkCard(data));

export const mapBrandedSearchPageCollection = (data: RawBrandedSearchPageCollection): BrandedSearchPageCollection => ({
	title: data.fields.title,
	description: asValueOrNull(data.fields.description),
	brandedsearchpages: mapBrandedSearchCards(data.fields.brandedsearchpages),
	cardVariant: asValueOrNull(data.fields.cardVariant),
	backgroundColor: data.fields.backgroundColor,
	enableWaveBackground: data.fields.enableWaveBackground,
});

export const mapBrandedSearchPageCollections = (
	data: RawBrandedSearchPageCollection[],
): BrandedSearchPageCollection[] => data.map((data) => mapBrandedSearchPageCollection(data));

export const mapHomepageTemplate = (data: EntryCollection<RawTemplateHomepage>): TemplateHomepage => {
	const { seo, headerImage, uspsTitle, usps, brandedsearchpages, brandedsearchpagesHeading, searchBlock } =
		data.items[0].fields;

	return {
		brandedsearchpagesHeading,
		seo: mapSeo(seo.fields),
		headerImage: headerImage.map((item) => item.fields && item.fields.file.url) ?? null,
		uspsTitle: uspsTitle,
		usps: usps.map(mapUsp),
		brandedsearchpages: mapBrandedSearchPageCollections(brandedsearchpages),
		id: data.items[0].sys.id,
		searchBlock: mapSearchBlock(searchBlock.fields),
	};
};

export const mapContentTemplate = (
	data: EntryCollection<RawContentTemplate>,
	dynamicContent?: string,
): ContentTemplate => {
	const { content, seo, title, wrapperVariant, headerImage } = data.items[0].fields;

	let extendedTitle = title;
	/** Handle dynamic titles. E.g. "Boeking {bookingNumber} to Boeking 1234" */
	if (!!dynamicContent && title.includes('bookingNumber')) {
		extendedTitle = message(title, { bookingNumber: dynamicContent });
		seo.fields.slug = `${seo.fields.slug}/${dynamicContent}`;
	}

	return {
		seo: mapSeo(seo.fields),
		title: extendedTitle,
		content,
		wrapperVariant,
		id: data.items[0].sys.id,
		headerImage: mapImageAsset(headerImage),
	};
};

export const mapFooterBlock = (data: EntryCollection<RawFooterBlock>): FooterBlock => {
	const qualityMarksIds = data.items[0].fields.qualityMarks.map((item) => item.sys.id);
	const paymentMethodsIds = data.items[0].fields.paymentMethods.map((item) => item.sys.id);
	const otherPartnersIds = data.items[0].fields.otherPartners.map((item) => item.sys.id);

	return {
		contactTitle: data.items[0].fields.contactTitle,
		contactLinks: data.items[0].fields.contactLinks.map(mapImageLink).filter(isNotEmpty),
		usps: data.items[0].fields.usps.map(mapUsp),
		logo: data.items[0].fields.logo,
		qualityMarks: filterIncludes<RawPartner>(data, qualityMarksIds).map(mapPartner),
		paymentMethods: filterIncludes<RawPartner>(data, paymentMethodsIds).map(mapPartner),
		otherPartners: filterIncludes<RawPartner>(data, otherPartnersIds).map(mapPartner),
		navigation: data.items[0].fields.navigation.map((item) => mapLink(item.fields)).filter(isNotEmpty),
	};
};

export const mapRichTextElements = (data: EntryCollection<RichTextElement>): RichTextElementMap => {
	const elementMap: RichTextElementMap = {};
	data.items.forEach((entry) => {
		if (entry.fields.key) elementMap[entry.fields.key] = entry.fields.value;
	});

	return elementMap;
};

export const mapAirportName = (airport: { value: string; code: string }, microCopies: MicroCopy): string => {
	if (microCopies[`airport.${airport.code}`]) {
		return microCopies[`airport.${airport.code}`];
	} else {
		return airport.value;
	}
};

export const mapButtonElement = (data: RawButtonElement): ButtonElement => {
	return {
		title: data.title,
		variant: data.variant,
		link: data.link,
		iconType: asValueOrNull(data.iconType),
	};
};

export const mapImageCard = (data: RawImageCard): ImageCard => {
	const imageUrl = data.image?.fields?.file?.url;
	const link = mapLink(data.link.fields);
	return {
		buttonTitle: data.buttonTitle,
		imageSrc: imageUrl ? imageUrl : '',
		link: link?.link ? link?.link : '',
		variant: data.variant,
		target: link?.target || '_blank',
	};
};

export const mapButtons = (data: Array<Entry<RawButtonElement>>): ButtonElement[] =>
	data.map((entry) => mapButtonElement(entry.fields));

export const mapImageCards = (data: Array<Entry<RawImageCard>>): ImageCard[] =>
	data.map((entry) => mapImageCard(entry.fields));

export const mapAlertElement = (data: Entry<RawAlertElement>): AlertElement => {
	const fields = data.fields;
	return {
		title: fields.title,
		variant: fields.variant,
		description: fields.description,
		direction: fields.direction,
		hyperlink: fields.hyperlink ? mapLink(fields.hyperlink.fields) : null,
	};
};

export const mapUnorderedList = (data: RawUnorderedList): UnorderedListType =>
	data.content.map(({ content }) => content.map((data) => data.content)) as UnorderedListType;

export const mapHeaderWithTickList = (data: Entry<RawHeaderWithTick>): HeaderWithTick => data.fields.headersWithTick;

export const mapButtonRow = (data: Entry<RawButtonRow>): ButtonRow => {
	const fields = data.fields;
	return {
		buttons: mapButtons(fields.buttons),
	};
};

export const mapImageGrid = (data: Entry<RawImageGrid>): ImageGrid => {
	const fields = data.fields;
	return {
		imageCards: fields && mapImageCards(fields.imageCards),
	};
};

export const mapImageLink = ({ fields }: RawImageLink): ImageLink | undefined => {
	if (!fields.link) return undefined;

	const imageUrl = fields.image.fields.file.url;
	const hoverImageUrl = fields.hoverImage ? fields.hoverImage.fields.file.url : undefined;

	return {
		title: fields.title,
		link: fields.link,
		image: imageUrl ? imageUrl : '',
		hoverImage: hoverImageUrl ? hoverImageUrl : '',
	};
};

export const getItemContentType = <T>(item: Entry<T>): string => item.sys.contentType.sys.id;

export const mapImageAsset = (image: Asset | undefined): MappedImageAsset | null => {
	return image?.fields?.file.details.image
		? {
				url: image.fields?.file?.url,
				title: image.fields?.title,
				width: image.fields?.file.details.image?.width,
				height: image.fields?.file.details.image?.height,
			}
		: null;
};

export const filterUserMenu = (
	rightMenuItems: Array<MenuLink | ElementNavItem>,
	microCopies: Record<string, string>,
): ElementNavItem | null => {
	const menu = rightMenuItems.find(
		(item): item is ElementNavItem => isElementNavItem(item) && item.listTitle === microCopies['userMenu.title'],
	);

	if (!menu) {
		errorHandler.report('No ElementNavItem found');
		return null;
	}

	return menu;
};

/** The first item of the navigation item list defines the types for all following items */
export const mapNavigationItems = (data: RawNavigationItem[]): Array<LinkList | ContentfulLink> | undefined => {
	const firstItemType = getItemContentType<RawLinkList[] | RawContentfulLink[]>(data[0]);

	if (firstItemType === 'link') {
		const filteredItems = data.filter(
			(navItem) => navItem.sys.contentType.sys.id === firstItemType,
		) as unknown as RawContentfulLink[];
		return filteredItems.map((item) => mapLink(item.fields)).filter(isNotEmpty);
	}
	if (firstItemType === 'elementLinkList') {
		const filteredItems = data.filter(
			(navItem) => navItem.sys.contentType.sys.id === firstItemType,
		) as unknown as RawLinkList[];

		const filteredLinkList = filteredItems.map((item) => {
			const mappedLinkList = mapLinkList(item, false);
			/** Limit the amount of links in linklists to 5 */
			return { ...mappedLinkList, links: mappedLinkList.links.slice(0, 5) };
		});

		if (filteredLinkList.length > 1) return filteredLinkList;
	}
	return undefined;
};

/** Extending navigation item links with an addition, e.g. the booking number */
export const extendNavigationItems = (data: ElementNavItem, linkAddition: string): ElementNavItem => {
	return {
		listTitle: data.listTitle,
		navItemType: data.navItemType,
		navigationItems: data.navigationItems?.map((navItem) => {
			return 'link' in navItem ? { ...navItem, link: `${navItem.link}/${linkAddition}` } : navItem;
		}),
	};
};

export const mapMenuItem = (data: RawElementNavItem): ElementNavItem => {
	const firstItemType = getItemContentType<RawLinkList[] | RawContentfulLink[]>(data.fields.navigationItems[0]);

	const getFirstNavItemType = (itemType: string): 'link' | 'elementLinkList' | undefined => {
		if (itemType === 'link') return 'link';
		if (itemType === 'elementLinkList') return 'elementLinkList';
		return undefined;
	};

	return {
		listTitle: data.fields.listTitle,
		navItemType: getFirstNavItemType(firstItemType),
		navigationItems: mapNavigationItems(data.fields.navigationItems),
	};
};

/** Image links are excluded for the headerNav */
export const mapLinkList = (data: RawLinkList, withImageLinks = true): LinkList => {
	const { fields } = data;
	const links = (fields.links as (RawContentfulLink | RawImageLink)[]).filter(
		(item) => !('image' in item.fields),
	) as RawContentfulLink[];
	const imageLinks = (fields.links as (RawContentfulLink | RawImageLink)[]).filter(
		(item) => 'image' in item.fields,
	) as RawImageLink[];

	return {
		title: fields.title,
		listTitle: fields.listTitle,
		key: fields.key,
		iconType: fields.iconType,
		links: [
			...links.map((link) => mapLink(link.fields)).filter(isNotEmpty),
			...(withImageLinks ? imageLinks.map(mapImageLink).filter(isNotEmpty) : []),
		],
	};
};

export const mapDynamicPageContent = (
	rawDynamicPageTemplateContent: RawDynamicPageTemplateContent,
): DynamicPageTemplateContent => {
	const {
		title,
		headerImageDesktop,
		headerImageTablet,
		headerImageMobile,
		headerTitle,
		headerCtaButton,
		headerSubTitle,
		seo,
		contentItemsCollection,
		sys,
		searchPanelDefaults,
	} = rawDynamicPageTemplateContent;

	return {
		id: sys.id,
		title: asValueOrNull(title),
		headerTitle: asValueOrNull(headerTitle),
		headerSubTitle: asValueOrNull(headerSubTitle),
		desktopHeaderImage: headerImageDesktop,
		tabletHeaderImage: headerImageTablet,
		mobileHeaderImage: headerImageMobile,
		headerCTAButton: headerCtaButton ? mapButtonElement(headerCtaButton) : null,
		seo: mapSeo(seo),
		searchPanelDefaults: asValueOrNull(searchPanelDefaults),
		contentItemsCollection: contentItemsCollection.items
			.map(mapContentItemsCollection)
			.filter((item): item is CollectionItem => item !== null),
	};
};

const mapContentItemsCollection = (contentItem: RawCollectionItem): CollectionItem | null => {
	switch (contentItem.itemName) {
		case 'BlockAccoCardList':
			return {
				itemName: contentItem.itemName,
				title: contentItem.title,
				variant: contentItem.accoListVariant,
				giataIds: contentItem.giataIds?.split(',') ?? null,
				entityIds: contentItem.entityIds?.split(',') ?? null,
				description: asValueOrNull(contentItem.description?.json),
			};

		case 'BlockImageGallery':
			return {
				itemName: contentItem.itemName,
				key: contentItem.key,
				title: contentItem.title,
				description: asValueOrNull(contentItem.description?.json),
				imagesCollection: contentItem.imagesCollection,
			};

		case 'BlockBrandedSearchPageCollection':
			return {
				itemName: contentItem.itemName,
				title: contentItem.title,
				brandedsearchpages: contentItem.brandedsearchpagesCollection.items.map((item) => ({
					title: item.title,
					imgSrc: asValueOrNull(item.cardImage?.url),
					cardImage: asValueOrNull(item.cardImage?.url),
					verticalImgSrc: asValueOrNull(item.verticalImage?.url),
					target: '_self',
					iconType: item.iconType,
					queryParams: item.seo.queryParams,
					slug: item.seo.slug,
					cheapestPrice: item.cheapestPrice,
					enableWaveBackground: contentItem.enableWaveBackground,
				})),
				description: asValueOrNull(contentItem.description?.json),
				cardVariant: asValueOrNull(contentItem.cardVariant),
				enableWaveBackground: contentItem.enableWaveBackground,
				backgroundColor: asValueOrNull(contentItem.backgroundColor),
			};

		case 'BlockVisualLinkList':
			return {
				itemName: contentItem.itemName,
				title: contentItem.title,
				linksCollection: asValueOrNull(
					contentItem.linksCollection.items.map((item) => mapLink(item)).filter((item) => !!item),
				),
				description: asValueOrNull(contentItem.description?.json),
				cardVariant: asValueOrNull(contentItem.cardVariant),
				backgroundColor: asValueOrNull(contentItem.backgroundColor),
			};

		case 'ElementRichText':
			contentItem.value.json.content.forEach((node) => {
				if (node.nodeType === 'embedded-asset-block') {
					const matchingAsset = contentItem.value.links.assets.block?.find(
						(item) => item.sys.id === node.data.target.sys.id,
					);
					if (matchingAsset) {
						// Add asset data to rich text to render the image
						node.data.fields = matchingAsset;
					}
				}
			});

			return {
				value: contentItem.value.json,
				key: asValueOrNull(contentItem.key),
				itemName: contentItem.itemName,
			};

		default:
			return null;
	}
};
