import { type FilterState } from '../stores/filtersStore/interfaces';

export const OWN_TRANSPORT_SLUG = 'autovakantie';

export const campaignsToHideTransport = ['all-inclusive'];

export function getAllowTransportFilters(filters: FilterState): boolean {
	if (filters.campaign) {
		return !campaignsToHideTransport.includes(filters.campaign);
	}

	return true;
}
