import { isZoover } from '@vakantiesnl/components/src/utils';
import { gql } from 'graphql-request';

import { FRAGMENT_IMAGE_FIELDS, FRAGMENT_SEO_FIELDS } from './fragments';

const brandedSearchTemplateQueryZvr = gql`
	query getBrandedSearchTemplate($slug: String!) {
		templateBrandedSearchPageCollection(limit: 1, where: { seo: { slug: $slug } }) {
			items {
				sys {
					id
				}
				title
				shortHeaderDescription
				readMoreText
				headerTitleImage {
					...imageAsset
				}

				description {
					json
				}

				footerTitle
				shortFooterDescription
				footerImagesCollection {
					items {
						... on Asset {
							...imageAsset
						}
					}
				}

				seo {
					...seoFields
				}

				brandedSearchPagesCollection {
					items {
						... on TemplateBrandedSearchPage {
							title
							cardImage {
								...imageAsset
							}
							seo {
								slug
							}
						}
					}
				}
			}
		}
	}

	${FRAGMENT_SEO_FIELDS}
	${FRAGMENT_IMAGE_FIELDS}
`;

const brandedSearchTemplateQueryVaknl = gql`
	query getBrandedSearchTemplate($slug: String!) {
		templateBrandedSearchPageCollection(limit: 1, where: { seo: { slug: $slug } }) {
			items {
				sys {
					id
				}
				title
				subtitle
				readMoreText

				description {
					json
				}

				footerTitle
				shortFooterDescription
				footerImagesCollection {
					items {
						... on Asset {
							...imageAsset
						}
					}
				}

				seo {
					...seoFields
				}

				brandedSearchPagesCollection {
					items {
						... on TemplateBrandedSearchPage {
							title
							headerImage {
								...imageAsset
							}
							seo {
								slug
							}
						}
					}
				}
			}
		}
	}

	${FRAGMENT_SEO_FIELDS}
	${FRAGMENT_IMAGE_FIELDS}
`;

export const searchTemplateQuery = isZoover ? brandedSearchTemplateQueryZvr : brandedSearchTemplateQueryVaknl;
