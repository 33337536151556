import { format } from 'url';

import { type QueryKey } from '@tanstack/react-query';
import { isZoover } from '@vakantiesnl/components/src/utils';

import { OWN_TRANSPORT_SLUG } from '../constants/setOwnTransport';
import { type BrandedSearchPageTemplate } from '../contentful/brandedSearchTemplate';
import { getCampaignMatch } from '../hooks/useSearchUrlBuilder';
import {
	themes as themesManifest,
	accoTypes as accoTypesManifest,
	countries as countriesManifest,
} from '../route-params';
import { type FilterState } from '../stores/filtersStore';
import { isNotEmpty } from '../util/arrayUtils';
import { getCanonicalUrl } from '../util/getCanonicalUrl';
import { replaceUndefinedByNull } from '../util/replaceUndefinedByNull';

export function getBrandedSearchTemplateQueryKey(contentfulSlug?: string): QueryKey {
	return ['branded-search-template', contentfulSlug || ''];
}

/**
 * Post-format because a page change should not effect the fetched template, so it
 * should not be part of the react query cache
 */
export function postFormatBrandedTemplate(
	template: BrandedSearchPageTemplate | null | undefined,
	filters: FilterState,
	contentfulSlug?: string,
): BrandedSearchPageTemplate | null {
	if (!template) return null;

	const page = filters.page;
	const ownTransport = isZoover ? filters.transport === 'OWN' : undefined;

	const queryParams = {
		...(page && page > 1 ? { page } : {}),
	};

	let pathname = contentfulSlug ? getCanonicalUrl(template.seo.canonical, contentfulSlug) : template.seo.canonical;

	if (ownTransport && !pathname.includes(OWN_TRANSPORT_SLUG)) {
		pathname = `${pathname}/${OWN_TRANSPORT_SLUG}`;
	}

	const canonical = !!Object.keys(queryParams).length
		? format({
				pathname,
				query: queryParams,
			})
		: pathname;

	// Don't update existing template, but create new object to prevent infinite loop because of a changed object
	const updatedTemplate: BrandedSearchPageTemplate = {
		...template,
		seo: {
			...template.seo,
			canonical,
		},
	};

	return replaceUndefinedByNull(updatedTemplate);
}

/**
 * Slug options depending on whether the page is a campaign:
 * - country?/region?/city?/accotype?/themes?/campaign?
 */
export function buildContentfulSlug(filters: FilterState): string | undefined {
	const {
		countries,
		regions,
		cities,
		themes,
		mealplans,
		campaign,
		accoType,
		isDealsRoute,
		chains,
		transport,
		tourOperators,
	} = filters;

	const countryValue = countries.length === 1 ? countriesManifest.find((c) => c === countries[0].slug) : undefined;

	if (isDealsRoute) {
		return buildUrl(countryValue, 'deals');
	}

	const transportValue = transport === 'OWN' ? OWN_TRANSPORT_SLUG : undefined;

	const accoTypeValue = accoType.length === 1 ? accoTypesManifest[accoType[0]] : undefined;

	const regionValue = countryValue && regions.length === 1 ? regions[0].slug : undefined;
	const cityValue = regionValue && cities.length === 1 ? cities[0].slug : undefined;

	const themeValue = themes.length === 1 ? themesManifest[themes[0]] : undefined;
	const chainValue = chains.length === 1 ? chains[0].slug : undefined;
	const tourOperatorValue = tourOperators.length === 1 ? tourOperators[0].slug : undefined;

	const mealplan = mealplans.length === 1 ? mealplanCodeToSlug[mealplans[0]] : undefined;

	const campaignMatcher = getCampaignMatch(filters);

	if (!campaignMatcher.hasValidCampaignMatch) {
		return buildUrl(
			countryValue,
			regionValue,
			cityValue,
			accoTypeValue,
			mealplan || themeValue,
			chainValue,
			tourOperatorValue,
			transportValue,
		);
	}

	// Don't add filters to the slug when the campaign already includes them
	return buildUrl(
		campaignMatcher.countries ? undefined : countryValue,
		campaignMatcher.regions ? undefined : regionValue,
		campaignMatcher.cities ? undefined : cityValue,
		campaignMatcher.accoType ? undefined : accoTypeValue,
		campaignMatcher.mealplans ? themeValue : mealplan || themeValue,
		campaign,
		chainValue,
		tourOperatorValue,
		transportValue,
	);
}

const mealplanCodeToSlug: Record<string, string> = {
	AI: 'all-inclusive',
};

function buildUrl(...paths: (string | undefined)[]): string | undefined {
	const nonEmptyPaths = paths.filter(isNotEmpty);

	if (!nonEmptyPaths.length) {
		return undefined;
	}

	return `/${nonEmptyPaths.join('/')}`;
}
