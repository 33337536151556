import { type LinkCard } from '@vakantiesnl/types/src';

import {
	type BrandedSearchPageQueryResponse,
	type BrandedSearchPageTemplate,
	type RawBrandedSearchPageTemplate,
	type RawVaknlBrandedSearchPageTemplate,
} from './types';
import { mapSeoDescription } from '../mapper';

export function mapBrandedSearchTemplate(
	data: BrandedSearchPageQueryResponse | null,
): BrandedSearchPageTemplate | null {
	if (!data?.templateBrandedSearchPageCollection?.items.length) {
		return null;
	}

	const template = data.templateBrandedSearchPageCollection.items[0];

	if ('subtitle' in template) {
		const { sys, brandedSearchPagesCollection, subtitle, footerImagesCollection, seo, description, ...rest } =
			template;

		return {
			...rest,
			id: sys.id,
			seo: {
				...seo,
				description: mapSeoDescription(seo.description),
			},
			shortHeaderDescription: subtitle,
			description: description?.json || null,
			headerImage: null,
			footerImages: footerImagesCollection.items,
			brandedSearchPages: mapBrandedSearchPageItems(brandedSearchPagesCollection.items),
		};
	}

	const { sys, brandedSearchPagesCollection, headerTitleImage, footerImagesCollection, description, seo, ...rest } =
		template;

	return {
		...rest,
		id: sys.id,
		seo: {
			...seo,
			description: mapSeoDescription(seo.description),
		},
		description: description?.json || null,
		headerImage: headerTitleImage,
		footerImages: footerImagesCollection.items,
		brandedSearchPages: mapBrandedSearchPageItems(brandedSearchPagesCollection.items),
	};
}

function mapBrandedSearchPageItems(
	items:
		| RawBrandedSearchPageTemplate['brandedSearchPagesCollection']['items']
		| RawVaknlBrandedSearchPageTemplate['brandedSearchPagesCollection']['items'],
): LinkCard[] {
	return items.map((item) => {
		const imgSrc = 'cardImage' in item ? item.cardImage?.url : item.headerImage?.url;

		return {
			title: item.title,
			imgSrc: imgSrc || null,
			target: '_self',
			slug: item.seo.slug,
		};
	});
}
